.spinner-wrapper {
  position: fixed;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner-text {
  color: $primary-color;
  font-weight: 600;
  margin: 7px 0;
}
