.landing-page {
  width: 100vw;
  height: 100vh;

  .landing-page-body {
    padding: 0 5%;

    .landing-page-nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-height: 50px;

      a:hover {
        text-decoration: underline;
      }
    }

    .landing-page-hero {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .landing-page-hero__top {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        .landing-title {
          font-family: $logo-font-family, sans-serif;
          font-size: 4.3rem;
          font-weight: bold;
          color: $primary-color;
        }
        .landing-subtitle {
          font-size: 1.3rem;
          font-weight: bold;
          color: $secondary-color;
          font-family: $font-nunito, sans-serif;
        }
        .landing-description {
          color: $secondary-color;
          font-family: $font-nunito, sans-serif;
          font-size: 18px;
          max-width: 50%;
        }
        p:nth-of-type(3) {
          margin-bottom: 0;
        }
      }

      .landing-page-hero__bottom {
        .background-hero {
          margin: 0 auto;

          img {
            width: auto;
            height: auto;
            max-width: 1400px;
            max-height: 600px;
            width: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
      }
    }
  }
}

.home-logo {
  cursor: pointer;
}
